body {
    background-color: black;
    color: white;
    overflow-x: hidden;
}

body::before {
    background-image: url("https://res.cloudinary.com/drantho/image/upload/,f_auto,q_65/v1654916389/daniel-koponyas-9MKEpRaAt6Y-unsplash_o1havx.jpg");
    background-size:cover;
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%; 
    height: 100%;
    opacity: 0.15;  
    filter: contrast(250%);  
    z-index: -1;
}

.ant-layout-header, .ant-menu-dark {
    background: rgba(0, 0, 0, 0.6) !important;
    width: 100%;
}

.ant-layout-content {
    margin: 50px 25px !important;
}

a:hover {
    text-decoration: underline;
}

h1, h2, h3 {
    color: white;
}

h2 {
    font-size: 2rem;
}

p, .technologies li, h3 {
    font-size: 0.9rem;
    letter-spacing: 2.1px;
}

.ant-layout {
    background: unset;
}

.gutter-row {
    margin: 0 0 50px 0;
}

.ant-col {
    padding: 0 25px !important;
}

.ant-card:hover {
    outline: 6px solid #1890ff;
}

.ant-card-cover {
    overflow: hidden;
}

.ant-card .ant-card-cover {
    aspect-ratio: 475 / 240;
}

.ant-card.square-ratio .ant-card-cover {
    aspect-ratio: 800 / 700;
}

.ant-card-cover img {
    width: 100%;
    height: auto;
    transition: width 0.5s ease-out;
}

.wide-ratio {
    aspect-ratio: 475 / 240;
}

.square-ratio {
    aspect-ratio: 800 / 700;
}

.ant-card:hover img {
    width: 110%;
}


element.style {
    display: block;
}

.left-align.ant-image {
    display: inline;
}

.ant-carousel {
    max-width: 1000px;
    margin: auto;
}

.ant-carousel .slick-dots-bottom {
    bottom: -22px;
}

.section {
    margin: auto;
    width: 100%;
    color: white;
}

.info {
    height: 100%;
    margin: 0 auto;
    padding: 25px;
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    outline: 0.5px solid rgba(255, 255, 255, 0.6);
}

.panel {
    width: 100%;
    position: relative;
    margin-bottom: 100px;
}

.panel img, .panel video {
    width: 100%;
    height: auto;
    aspect-ratio: 800 / 350;
    display: block;
    margin: auto;
    border: 2px solid white;
}

.portrait {
    border-radius: 50%;
    width: 100%;
    height: auto;
    aspect-ratio: 1 / 1;
    margin: 20px 0 0;
    /* padding: 25%; */
}


.intro-text {
    font-size: 1.5rem;
}

.verb {
    text-align: center;
    font-size: 3.5rem;
    font-style: italic;
}

.ant-carousel .slick-dots li {
    background-color: rgba(0, 0, 0, 0.6);
}

.ant-carousel .slick-dots li.slick-active button{
    background:#1890ff !important;
    color:#1890ff !important;
}

#contact .ant-row {
    padding: 10px 0 0 0;
}

.ant-col-sm-24 {
    /* margin: auto; */
}

.resume-ul li {
    font-size: 1.25em;
}

@media only screen and (min-width: 600px) {
    .verb {
        margin-bottom: 0;
    }

    .ant-col {
        padding: 25px;
    }

    .intro-text {
        font-size: 2.3rem;
    }

    .section {
        margin-top: 100px;
        width: 100%;
        max-width: 1600px;
        color: white;
    }

    p, .technologies li, h3 {
        font-size: 1.2rem;
        letter-spacing: 2.1px;
    }

    #contact .ant-row {
        padding: 100px 0 0 0;
    }

    .portrait {
        margin-top: 50px;
        padding: 25%;
    }
}

@media only screen and (min-width: 768px) {
    .portrait {
        padding: 15%;
    }
}

@media only screen and (min-width: 1200px) {
    .portrait {
        padding: 25%;
    }
}